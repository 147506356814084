<template>
  <div class="goalBarContainer days-goal">
    <strong class="modelsGoalsCard-name">{{ goal.name }}</strong>
    <div class="progressBar-container" v-for="(award, idx) in goalAward.value + 1" :key="idx">
      <div class="progressBar">
        <div class="progressBar-point-start ">
          <div class="progressBar-circle start">0</div>
          <div class="progressBar-text" v-if="idx === 0">
            <p class="progressBar-text-label">Fecha de ingreso</p>
            <p class="progressBar-text-message">
              {{ $global.dateLocaleFormat(modelData.entrydate ? modelData.entrydate.substring(0, 23) : new Date().toISOString()) }}
            </p>
          </div>
          <div class="progressBar-text" v-else>
            <p class="progressBar-text-label">Meta</p>
            <p class="progressBar-text-message">{{ $global.parseDaysToDate(goal.goal * idx, { hideDays: true }) }}</p>
          </div>
        </div>
        <div :style="`width: ${idx === goalAward.value ? percent : '100'}%`" class="progressBar-bar positive"></div>
        <div :style="`left: ${idx === goalAward.value ? percent : '100'}%`" class="progressBar-point-elapsed">
          <div class="progressBar-circle proccess">
            {{ idx === goalAward.value ? Math.floor(percent) : "100" }}
            <span>%</span>
          </div>
          <div class="progressBar-text"></div>
        </div>
        <div class="progressBar-point-end">
          <div class="progressBar-circle end">
            100
            <span>%</span>
          </div>
          <div class="progressBar-text">
            <p class="progressBar-text-label">Meta</p>
            <p class="progressBar-text-message">{{ $global.parseDaysToDate(goal.goal * (idx + 1), { hideDays: true }) }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="progressBar-legend">
      <div class="progressBar-text">
        <div class="progressBar-text-legend">
          <div class="progressBar-legend-box withUs"></div>
          <p class="progressBar-text-label">Tiempo con nosotros</p>
        </div>
        <p class="progressBar-text-message">
          {{ $global.parseDaysToDate(goalAward.value * goal.goal + progress) || "0 días" }}
        </p>
      </div>
      <div class="progressBar-text">
        <div class="progressBar-text-legend">
          <div class="progressBar-legend-box"></div>
          <p class="progressBar-text-label">Tiempo para el siguiente premio</p>
        </div>
        <p class="progressBar-text-message">
          {{ goal.goal - progress >= 0 ? $global.parseDaysToDate(goal.goal - progress) : "0 días" }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["modelData", "progress", "goal", "isMonitor", "awards"],
  computed: {
    percent() {
      const percent = (this.progress * 100) / this.goal.goal;
      return percent >= 100 ? 100 : percent;
    },
    goalAward() {
      return this.awards[0];
    },
  },
};
</script>
