<template>
  <div :class="['inputEdit__container', isGoalEdit ? `c${goal._id} isEdit` : '']">
    <strong v-if="isGoalEdit" class="inputEdit__title">Editar Meta</strong>
    <strong v-else class="inputEdit__title">Editar Progreso de Meta</strong>
    <div class="inputEdit__select" v-if="!isGoalEdit">
      <p>Selecciona una opción</p>
      <b-form-radio-group v-model="selected" :options="options" value-field="item" text-field="name"></b-form-radio-group>
    </div>
    <div class="inputEdit__field" v-if="isGoalEdit">
      <p class="inputEdit__label">Nombre de la meta</p>
      <input class="inputEdit__input" type="text" v-model="goalName" placeholder="Escribe un nombre" />
    </div>
    <div class="inputEdit__timeInput">
      <p class="inputEdit__label">Tiempo</p>
      <input class="inputEdit__input" type="text" v-model="input" placeholder="1a 6m 1d" />
      <div class="inputEdit__infoBanner">
        <p>Escriba de esta forma: 1a 6m</p>
        <p>a = Años</p>
        <p>m = Mes</p>
        <p>d = Días</p>
      </div>
      <div v-if="message" class="inputEdit__timeToAdd">
        <div class="inputEdit__timeToAddBar"></div>
        <p>{{ message }}</p>
      </div>
    </div>
    <div class="inputEdit__field" v-if="!isGoalEdit">
      <p class="inputEdit__label">Descripción</p>
      <div class="inputEdit__reason">
        <textarea gbar class="inputEdit__input" placeholder="Descripción" v-model="reason"></textarea>
      </div>
    </div>
    <ToggleInput v-if="isGoalEdit" :label="check ? 'Ocultar meta' : 'Activar meta'" :checked="check" @isChecked="handleCheck" />
    <div class="inputEdit__buttons">
      <button class="inputEdit__acceptBtn generalBtn" @click="sendUpdate" :disabled="buttonDisabled">
        Aceptar
      </button>
      <button class="inputEdit__cancelBtn generalBtn" @click="$emit('closeEditTime'), getInitValues()">Cancelar</button>
    </div>
  </div>
</template>
<script>
import ToggleInput from "../../input/ToggleInput.vue";

export default {
  components: { ToggleInput },
  props: ["modelData", "isGoalEdit", "goal", "progress", "awards"],
  data() {
    return {
      selected: "decrease",
      options: [
        { item: "decrease", name: "Disminuir" },
        { item: "increase", name: "Aumentar" },
      ],
      message: "",
      goalName: "",
      input: "",
      value: "",
      check: true,
      reason: "",
    };
  },
  methods: {
    async sendUpdate() {
      if (!this.isGoalEdit) {
        let valToSend = 0;
        if (this.selected === "decrease") {
          const maxDecrease = this.goal.goal * this.awards[0].value + this.progress;
          this.value > maxDecrease && (this.value = maxDecrease);
          valToSend = this.progress - this.value;
        }
        if (this.selected === "increase") valToSend = +this.progress + this.value;
        this.$emit("updateModel", {
          progress: valToSend,
          goal: this.goal,
          id: this.modelData._id,
          ref: "dates",
          netValue: this.value,
          action: this.selected,
          reason: this.reason,
        });
      }

      if (this.isGoalEdit) {
        const goalUpdate = { name: this.goalName, id: this.goal._id, enable: this.check, ref: "dates" };
        if (this.goal.goal !== this.value) goalUpdate.goal = this.value;
        this.$emit("updateGoal", goalUpdate);
      }
    },
    saveModifyReport() {},
    getInitValues() {
      if (this.isGoalEdit) {
        this.goalName = this.goal.name;
        this.check = this.goal.enable;
        this.input = this.$global.getInputValue(this.goal.goal, "days");
      }
    },
    handleCheck(val) {
      this.check = val;
    },
  },
  computed: {
    buttonDisabled() {
      if ((this.isGoalEdit && this.message) || (this.isGoalEdit && this.goalName)) {
        return false;
      }
      if (!this.isGoalEdit && this.message && this.reason) {
        return false;
      }
      return true;
    },
  },
  watch: {
    input() {
      const { message, days } = this.$global.parseInputToTime(this.input.toLowerCase(), "days");
      this.message = message;
      this.value = days;
    },
  },
  beforeMount() {
    this.getInitValues();
  },
};
</script>
