<template>
  <div class="goalBarContainer">
    <strong class="modelsGoalsCard-name">{{ goal.name }}</strong>
    <div class="progressBar-container">
      <div class="progressBar">
        <div class="progressBar-point-start ">
          <div class="progressBar-circle start">0</div>
          <div class="progressBar-text"></div>
        </div>
        <div class="progressBar-subGoals" v-for="subGoal in goal.subGoals" :key="subGoal._id">
          <div :style="`left: ${(subGoal.goal * 100) / goal.goal}%;`" class="progressBar-point-subgoal">
            <div :class="['progressBar-circle proccess', progress >= subGoal.goal ? 'finished' : '']"></div>
            <div class="progressBar-text">
              <p class="progressBar-text-message">{{ Math.floor(subGoal.goal / 60) }}h</p>
            </div>
          </div>
        </div>
        <div :style="`width: ${percent}%`" class="progressBar-bar positive"></div>
        <div :style="`left: ${percent}%`" class="progressBar-point-elapsed">
          <div class="progressBar-circle proccess">
            {{ Math.floor(percent) }}
            <span>%</span>
          </div>
          <div class="progressBar-text">
            <p class="progressBar-text-label">Tiempo trabajado</p>
            <p class="progressBar-text-message">{{ $global.parseMinutesToTime(progress) || "0 horas" }}</p>
          </div>
        </div>
        <div class="progressBar-point-end">
          <div class="progressBar-circle end">
            100
            <span>%</span>
          </div>
          <div class="progressBar-text">
            <p class="progressBar-text-label">Meta</p>
            <p class="progressBar-text-message">{{ $global.parseMinutesToTime(goal.goal) }}</p>
          </div>
        </div>
      </div>
      <div class="progressBar-legend">
        <div class="progressBar-text">
          <div class="progressBar-text-legend">
            <div class="progressBar-legend-box"></div>
            <p class="progressBar-text-label">
              Tiempo restante
            </p>
          </div>
          <p class="progressBar-text-message">
            {{ goal.goal - progress >= 0 ? $global.parseMinutesToTime(goal.goal - progress) : "0 Horas 0 minutos" }}
          </p>
        </div>
      </div>
    </div>
    <div class="progressBar-awards" :key="awardsKey">
      <Award v-for="award in awards" :key="award._id" :award="award" :modelId="modelData._id" :isBar="true" />
    </div>
  </div>
</template>
<script>
import Award from "../awards/Award.vue";

export default {
  components: { Award },
  props: ["modelData", "progress", "goal", "awards"],
  data() {
    return {
      awardsKey: 0,
    };
  },
  computed: {
    percent() {
      const percent = (this.progress * 100) / this.goal.goal;
      return percent >= 100 ? 100 : percent;
    },
  },
  watch: {
    awards() {
      this.awardsKey++;
    },
  },
};
</script>
