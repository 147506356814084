<template>
  <div :class="['inputEdit__container', isGoalEdit ? `c${goal._id} isEdit` : '']">
    <strong v-if="isGoalEdit" class="inputEdit__title">Editar Meta</strong>
    <strong v-else class="inputEdit__title">Editar Progreso de Meta</strong>
    <div class="inputEdit__select" v-if="!isGoalEdit">
      <p>Selecciona una opción</p>
      <b-form-radio-group v-model="selected" :options="options" value-field="item" text-field="name"></b-form-radio-group>
    </div>
    <div class="inputEdit__field" v-if="isGoalEdit">
      <p class="inputEdit__label">Nombre de la meta</p>
      <input class="inputEdit__input" type="text" v-model="goalName" placeholder="Escribe un nombre" />
    </div>
    <div class="inputEdit__timeInput">
      <p class="inputEdit__label">Dolares</p>
      <div>
        <input class="inputEdit__input" type="number" v-model.number="value" placeholder="Escribe la cantidad" />
      </div>
      <div v-if="message" class="inputEdit__timeToAdd">
        <div class="inputEdit__timeToAddBar"></div>
        <p>{{ message }}</p>
      </div>
    </div>
    <div class="inputEdit__subGoals" v-if="isGoalEdit && goal.subGoals.length">
      <strong class="inputEdit__title">Sub Metas</strong>
      <ol class="inputEdit__subGoalsList">
        <li v-for="subGoal in goal.subGoals" :key="subGoal._id">
          <SubGoal :subGoal="subGoal" type="dollars" :ref="subGoal._id" />
        </li>
      </ol>
    </div>
    <div class="resetTimer" v-if="isGoalEdit">
      <strong class="inputEdit__title">Reinicio de meta</strong>
      <div class="resetTimer__container">
        <div class="resetTimer__day">
          <p class="inputEdit__label">Día</p>
          <input class="inputEdit__input" v-model.number="day" type="number" placeholder="00" min="1" max="28" list="days" autocomplete="false" />
          <datalist id="days" v-if="days">
            <option v-for="(didx, day) in days" :value="days[day]" :key="`day-${day}`"></option>
          </datalist>
        </div>
        <div class="resetTimer__hour">
          <p class="inputEdit__label">Hora</p>
          <input type="time" class="inputEdit__input" v-model="hour" />
        </div>
      </div>
    </div>
    <ToggleInput v-if="isGoalEdit" :label="check ? 'Ocultar meta' : 'Activar meta'" :checked="check" @isChecked="handleCheck" />
    <div class="inputEdit__buttons">
      <button class="inputEdit__acceptBtn generalBtn" @click="sendUpdate" :disabled="buttonDisabled">
        Aceptar
      </button>
      <button class="inputEdit__cancelBtn generalBtn" @click="$emit('closeEditTime'), getInitValues()">Cancelar</button>
    </div>
  </div>
</template>
<script>
import SubGoal from "./SubGoal.vue";
import ToggleInput from "../../input/ToggleInput.vue";

export default {
  components: { SubGoal, ToggleInput },
  props: ["modelData", "isGoalEdit", "goal", "progress"],
  data() {
    return {
      goalName: "",
      value: "",
      day: null,
      hour: null,
      days: [],
      selected: "decrease",
      options: [
        { item: "decrease", name: "Disminuir" },
        { item: "increase", name: "Aumentar" },
      ],
      check: null,
    };
  },
  methods: {
    handleCheck(val) {
      this.check = val;
    },
    async sendUpdate() {
      if (!this.isGoalEdit) {
        let updateAccum;
        let progress;
        if (this.selected === "decrease") {
          updateAccum = this.value > this.progress ? -this.progress : -this.value;
          progress = this.value > this.progress ? 0 : this.progress - this.value;
        }
        if (this.selected === "increase") {
          updateAccum = this.value;
          progress = this.progress + this.value;
        }
        this.$emit("updateModel", { progress, updateAccum, goal: this.goal, id: this.modelData._id, ref: "dollars" });
      }

      if (this.isGoalEdit) {
        const subGoals = [];
        for (const subGoal of this.goal.subGoals) {
          const [subGoalData] = this.$refs[subGoal._id];
          if (subGoalData.isEdit && subGoalData.value) subGoals.push({ value: subGoalData.value, _id: subGoal._id });
        }
        const goalUpdate = { reset: { hour: this.hour, day: this.day }, name: this.goalName, id: this.goal._id, enable: this.check, ref: "dollars" };
        if (this.goal.goal !== this.value) goalUpdate.goal = this.value;
        if (subGoals.length) goalUpdate.subGoals = subGoals;
        if (this.day !== this.goal?.reset?.day || this.hour !== this.goal?.reset?.hour) goalUpdate.reset = { hour: this.hour, day: this.day };
        this.$emit("updateGoal", goalUpdate);
      }
    },
    getInitValues() {
      if (this.isGoalEdit) {
        this.value = this.goal.goal;
        this.check = this.goal.enable;
        this.goalName = this.goal.name;
        this.day = this.goal?.reset?.day;
        this.hour = this.goal?.reset?.hour;
      }
    },
  },
  computed: {
    buttonDisabled() {
      if (this.isGoalEdit) {
        if ((this.hour && !this.day) || (!this.hour && this.day)) {
          return true;
        }
        if (this.day < 1 || this.day > 28) {
          return true;
        }
        if (this.message || this.goalName || (this.hour && this.day)) {
          return false;
        }
        if (this.isGoalEdit && !this.hour && !this.day) {
          return true;
        }
      }
      if (!this.isGoalEdit && this.message) {
        return false;
      }
      return true;
    },
    message() {
      if (!this.value) {
        return null;
      }
      return this.$global.formatPrice(this.value);
    },
  },
  beforeMount() {
    for (let d = 1; d <= 28; d++) {
      this.days.push(d);
    }
    this.getInitValues();
  },
};
</script>
