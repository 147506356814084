<template>
  <div class="inputEdit__container">
    <strong class="inputEdit__title">Agregar Badge</strong>
    <div class="inputEdit__field">
      <p class="inputEdit__label">Nombre</p>
      <input class="inputEdit__input" type="text" placeholder="Escribe un nombre" v-model="name" />
    </div>
    <div class="inputEdit__field year">
      <p class="inputEdit__label">Año</p>
      <input class="inputEdit__input" type="number" placeholder="0000" list="years" v-model.number="year" />
    </div>
    <div class="inputEdit__field">
      <p class="inputEdit__label">Badge</p>
      <input class="inputEdit__input" type="file" accept=".jpg,.jpeg,.png" placeholder="Agrega una imagen" @change="setFile" />
    </div>
    <div class="inputEdit__buttons">
      <button class="inputEdit__acceptBtn generalBtn" @click="handleAction" :disabled="buttonDisabled">
        Aceptar
      </button>
      <button class="inputEdit__cancelBtn generalBtn" @click="$emit('closeBadgeModal')">Cancelar</button>
    </div>
  </div>
</template>
<script>
import alerts from "@/js/swalAlerts";

export default {
  props: { modelId: String, badge: Object },
  data() {
    return {
      ylimit: 0,
      cyear: new Date().getFullYear(),
      years: [],
      name: null,
      year: null,
      img: null,
    };
  },
  methods: {
    setFile({ srcElement }) {
      const isValid = this.$global.validFile(srcElement.files, "image");
      if (!isValid) return false;

      this.img = srcElement.files[0];
    },
    handleAction() {
      this.badge ? this.updateBadge() : this.createBadge();
    },
    async createBadge() {
      const { isConfirmed } = await alerts.dataSendConfirm({ message: "El badge será creado", buttonTextYes: "Crear", buttonTextNo: "Cancelar" });
      if (isConfirmed) {
        alerts.dataSending();
        const content = new FormData();
        if (this.name) content.append("name", this.name);
        content.append("year", this.year);
        content.append("img", this.img);
        const { success, message } = await this.$store.dispatch("goals/modifyBadge", { content, modelId: this.modelId, action: "post" });
        success ? alerts.dataSendSuccess({ message }) : alerts.dataSendError({ message });
        this.$emit("closeBadgeModal");
      }
    },
    async updateBadge() {
      // Validate if some data change
      if (!(this.badge.name !== this.name) && !(this.badge.year !== this.year) && !this.img) return this.$emit("closeBadgeModal");

      const { isConfirmed } = await alerts.dataSendConfirm({
        message: "El badge será actualizado",
        buttonTextYes: "Actualziar",
        buttonTextNo: "Cancelar",
      });
      if (isConfirmed) {
        alerts.dataSending();
        const content = new FormData();
        if (this.name !== this.badge.name) content.append("name", this.name);
        if (this.year !== this.badge.year) content.append("year", this.year);
        if (this.img) content.append("img", this.img);
        content.append("oldBadge", JSON.stringify(this.badge));
        const { success, message } = await this.$store.dispatch("goals/modifyBadge", { content, modelId: this.modelId, action: "put" });
        success ? alerts.dataSendSuccess({ message }) : alerts.dataSendError({ message });
        this.$emit("closeBadgeModal");
      }
    },
  },
  computed: {
    buttonDisabled() {
      if ((!this.year && !this.badge) || (!this.img && !this.badge)) {
        return true;
      }
      if (this.year > 1975 && this.year <= this.cyear) {
        return false;
      }
      return true;
    },
  },
  beforeMount() {
    if (this.badge) {
      this.name = this.badge.name;
      this.year = this.badge.year;
    }
    for (let y = this.cyear - this.ylimit - 50; y <= this.cyear - this.ylimit; y++) {
      this.years.push(y);
    }
  },
};
</script>
