<template>
  <div>
    <div class="inputEdit__subGoalDesc">
      <div>
        <p v-if="!isEdit && type === 'hours'">{{ $global.parseMinutesToTime(subGoal.goal) }}</p>
        <p v-else-if="!isEdit && type === 'dollars'">{{ $global.formatPrice(subGoal.goal) }}</p>
        <input v-else class="inputEdit__input" v-model="input" type="text" />
      </div>
      <div @click="editSubgoal">
        <iconic name="edit_pencil" />
      </div>
    </div>
    <div v-if="message && isEdit" class="inputEdit__timeToAdd">
      <div class="inputEdit__timeToAddBar"></div>
      <p>{{ message }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: ["subGoal", "type"],
  data() {
    return {
      isEdit: null,
      input: "",
      message: "",
      value: null,
    };
  },
  methods: {
    editSubgoal() {
      this.isEdit = !this.isEdit;
      if (this.type === "hours") this.input = this.$global.getInputValue(this.subGoal.goal, "hours");
      if (this.type === "dollars") this.input = this.subGoal.goal;
    },
  },
  watch: {
    input() {
      if (this.type === "hours") {
        const { message, mins } = this.$global.parseInputToTime(this.input.toLowerCase(), "hours");
        this.value = mins;
        this.message = message;
      }

      if (this.type === "dollars") {
        this.value = +this.input;
        this.message = this.$global.formatPrice(this.input);
      }
    },
    subGoal() {
      this.isEdit = false;
    },
  },
};
</script>
