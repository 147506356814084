<template>
  <div class="badgeItem">
    <img @click="isModel ? (showImgModal = true) : ''" class="badgeItem-image" :src="badge.img" :alt="badge.name" />
    <div class="badgeItem-btns" v-if="!isModel">
      <button @click="editModal = !editModal" class="badgeItem-btns-edit"><iconic name="edit_pencil" /></button>
      <BadgeForm v-if="editModal" class="badgeItem-editForm" @closeBadgeModal="editModal = false" :badge="badge" :modelId="modelId" />
      <div v-if="editModal" @click="handleClose" class="badgeItem-editForm-mask"></div>
      <button @click="deleteBadge" class="badgeItem-btns-delete"><iconic name="trash" /></button>
    </div>
    <div @click="showDetail" class="badgeItem-modal" v-if="showImgModal">
      <div fcenter class="badgeItem-modal-close"><i class="fa fa-times"></i></div>
      <div fcenter class="badgeItem-modal-img">
        <img class="badgeItem-modal-img-el" :src="badge.img" :alt="badge.name" />
      </div>
    </div>
  </div>
</template>
<script>
import alerts from "@/js/swalAlerts";
import BadgeForm from "./inputTypes/Badge.vue";

export default {
  props: {
    badge: Object,
    modelId: String,
    isModel: Boolean,
  },
  components: { BadgeForm },
  data() {
    return {
      editModal: false,
      showImgModal: false,
    };
  },
  methods: {
    async deleteBadge() {
      const { isConfirmed } = await alerts.dataSendConfirm({
        message: "El badge será eliminado permanentemente",
        buttonTextYes: "Aceptar",
        buttonTextNo: "Cancelar",
      });
      if (isConfirmed) {
        alerts.dataSending();
        const { success, message } = await this.$store.dispatch("goals/modifyBadge", {
          content: this.badge,
          modelId: this.modelId,
          action: "patch",
        });
        success ? alerts.dataSendSuccess({ message }) : alerts.dataSendError({ message });
      }
    },
    handleClose({ path }) {
      const isInModal = path.some((el) => el.classList?.contains("inputEdit__container badgeItem-editForm"));
      if (!isInModal) this.editModal = false;
    },
    showDetail({ path }) {
      const isInImg = path.some((el) => el.classList?.contains("badgeItem-modal-img-el"));
      if (!isInImg) this.showImgModal = false;
    },
  },
};
</script>
<style lang="scss">
.badgeItem {
  position: relative;
  &-modal {
    position: fixed;
    min-height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 12;
    &-img {
      height: 100%;
      img {
        width: 80%;
        max-width: 450px;
      }
    }
    &-close {
      position: fixed;
      top: 70px;
      right: 15px;
      width: 44px;
      height: 44px;
      z-index: 2;
      color: #fff;
      background-color: rgba(189, 9, 9, 0.458824);
      border-radius: 50%;
      transition: 0.3s ease-in-out;
      &:hover {
        background-color: #bd0909;
      }
    }
  }
  &-image {
    width: 130px;
    height: 130px;
    object-fit: contain;
  }
  &-editForm {
    position: absolute;
    top: 27px;
    left: 90px;
    &-mask {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      display: block;
    }
  }
  &-btns {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    gap: 3px;
    &-edit,
    &-delete {
      background: $lipstick;
      color: white;
      border: none;
      border-radius: 6px;
      padding: 0 4px;
      outline: none;
      &:hover {
        transform: scale(1.1);
      }
      &:focus {
        outline: none;
      }
      i {
        svg {
          width: 13px;
          height: 13px;
        }
      }
    }
  }
}
</style>
