<template>
  <div v-if="goal">
    <Dates
      v-if="goal.type === 'days'"
      ref="dates"
      :modelData="modelData"
      :goal="goal"
      :progress="progress"
      :isGoalEdit="isGoalEdit"
      :awards="awards"
      @updateModel="updateModel"
      @updateGoal="updateGoal"
      @closeEditTime="$emit('closeEditTime')"
    />
    <Hours
      v-if="goal.type === 'hours'"
      ref="hours"
      :modelData="modelData"
      :goal="goal"
      :progress="progress"
      :isGoalEdit="isGoalEdit"
      @updateModel="updateModel"
      @updateGoal="updateGoal"
      @closeEditTime="$emit('closeEditTime')"
    />
    <Dollars
      v-if="goal.type === 'dollars'"
      ref="dollars"
      :modelData="modelData"
      :goal="goal"
      :progress="progress"
      :isGoalEdit="isGoalEdit"
      @updateModel="updateModel"
      @updateGoal="updateGoal"
      @closeEditTime="$emit('closeEditTime')"
    />
    <TopModel
      v-if="goal.type === 'topmodel'"
      ref="topmodel"
      :goal="goal"
      :isGoalEdit="isGoalEdit"
      @updateGoal="updateGoal"
      @closeEditTime="$emit('closeEditTime')"
    />
  </div>
</template>
<script>
import Dates from "./inputTypes/Dates.vue";
import Dollars from "./inputTypes/Dollars.vue";
import Hours from "./inputTypes/Hours.vue";
import Quantity from "./inputTypes/Quantity.vue";
import TopModel from "./inputTypes/TopModel.vue";
import alerts from "@/js/swalAlerts";

export default {
  components: { Dates, Dollars, Hours, Quantity, TopModel },
  props: ["modelData", "goal", "isGoalEdit", "progress", "awards"],
  methods: {
    async updateGoal({ ref, ...info }) {
      const { isConfirmed } = await alerts.dataSendConfirm({ message: `La meta será actualizada` });
      if (!isConfirmed) return;
      alerts.dataSending();

      const response = await this.$store.dispatch("goals/updateGoal", info);

      if (["days", "hours", "topmodel"].includes(this.goal.type)) {
        this.$refs[ref].input = this.$global.getInputValue(this.goal.goal, this.goal.type === "topmodel" ? this.goal.subType : this.goal.type);
      }
      this.$refs[ref].value = this.goal.goal;
      this.$refs[ref].goalName = this.goal.name;
      this.$emit("closeEditTime");

      response
        ? alerts.dataSendSuccess({ message: "La meta fue actualizada con éxito" })
        : alerts.dataSendError({ message: "La meta no pudo ser actualizada" });
    },
    async updateModel({ ref, ...info }) {
      const { isConfirmed } = await alerts.dataSendConfirm({ message: `El progreso de la modelo será actualizado` });
      if (!isConfirmed) return;
      alerts.dataSending();

      const response = await this.$store.dispatch("goals/updateModelGoal", { ...info, awards: this.awards });
      this.$refs[ref].input = "";
      this.$refs[ref].value = "";
      this.$refs[ref].selected = "decrease";
      this.$refs[ref].reason = "";
      this.$emit("closeEditTime");
      response
        ? alerts.dataSendSuccess({ message: "Meta de la modelo actualizada" })
        : alerts.dataSendError({ message: "Fallo al actualizar la meta de la modelo" });
    },
  },
};
</script>

<style lang="scss">
.inputEdit {
  &__container {
    width: 262px;
    background: white;
    position: absolute;
    z-index: 90;
    box-shadow: $modalShadow;
    border-radius: 2px;
    padding: 16px 16px 23px;
    margin-left: -131px;
    top: 40px;

    .toggle {
      margin-top: 10px;
    }

    .toggle-switch {
      width: 48px;
      height: 22px;
      &::before {
        width: 14px;
        height: 14px;
      }
    }

    .toggle-label {
      vertical-align: top;
      font-size: 14px;
    }
  }

  &__reason {
    textarea {
      height: 80px;
      word-break: break-word;
    }
  }

  &__title {
    font-size: 16px;
  }

  &__input {
    width: 100%;
    height: 29px;
    font-size: 14px;
    padding: 3px 8px;
    border: 1px solid $silver;
    border-radius: 2px;
    outline: none;
    &:hover .inputEdit__infoBanner {
      display: block;
    }
    &:read-only {
      border: none;
    }
    &[type="file"] {
      &::-webkit-file-upload-button {
        display: none;
      }
      &::before {
        content: attr(placeholder);
        margin-right: $mpadding/2;
        opacity: 0.9;
      }
      &:active {
        background-color: $alto;
      }
    }
  }

  &__input:hover ~ &__infoBanner {
    display: block;
  }

  &__label {
    font-size: 14px;
    margin-top: 7px;
  }

  &__field {
    &.year {
      position: relative;
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="number"] {
        -moz-appearance: textfield;
      }
      datalist {
        max-width: 100%;
      }
    }
  }

  &__infoBanner {
    position: absolute;
    margin-top: -170px;
    background: $mine_shaft;
    color: white;
    padding: 12px;
    display: none;
    z-index: 2;

    p {
      font-size: 14px;
    }

    p:nth-child(2) {
      margin-top: 8px;
    }
  }

  &__subGoals {
    margin-top: 13px;
  }

  &__subGoalsList {
    margin-top: 7px;
    font-size: 14px;

    li {
      margin-left: 13px;
      margin-bottom: 5px;
    }
  }

  &__subGoal {
    list-style-type: decimal;
    display: flex;
    margin-bottom: 5px;
    flex-direction: column;
  }

  &__subGoalDesc {
    display: flex;
    justify-content: space-between;
    align-items: center;

    i {
      cursor: pointer;
    }

    strong {
      display: inline-block;
      width: 14px;
    }

    input {
      width: 160px;
    }
  }

  &__subGoalEdit {
    margin-bottom: 12px;
  }

  &__timeToAdd {
    margin-top: 14px;
    p {
      font-size: 14px;
      font-weight: bold;
    }
  }

  &__timeToAddBar {
    width: 100%;
    height: 8px;
    background: $primary_color;
    border-radius: 5px;
  }

  &__buttons {
    margin-top: 20px;
    @include Row;
    gap: 16px;
  }

  &__acceptBtn {
    background-color: $lipstick;
    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
      z-index: -1;
    }
  }

  &__cancelBtn {
    background-color: black;
  }

  &__select {
    margin-top: 8px;
    span {
      font-size: 14px;
    }
  }

  &__selectGroup {
    margin-top: 8px;
  }
}

.resetTimer {
  margin-top: 13px;
  margin-bottom: 10px;
  &__container {
    display: flex;
    gap: 15px;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    width: 70px;
    -moz-appearance: textfield;
  }

  input[type="time"] {
    width: 144px;
  }

  input[type="number"]::-webkit-calendar-picker-indicator {
    margin-bottom: 5px;
  }
}

.isEdit {
  display: none;
  margin-left: -262px;
  margin-top: 20px;
}

@media (min-width: $tablet_width) {
  .isEdit {
    margin-left: -218px;
    margin-top: 0;
  }
}

@media (min-width: $tv_width) {
  .inputEdit__container {
    top: 85px;
  }

  .isEdit {
    top: 41px;
  }
}

.hidden {
  display: none;
}
</style>
