<template>
  <div class="goalBarContainer" v-if="$store.getters['user/getRole'] !== 'monitor'">
    <strong class="modelsGoalsCard-name">{{ goalData.goalData.goalName }}</strong>
    <div class="progressBar-container">
      <div class="progressBar">
        <div class="progressBar-point-start ">
          <div class="progressBar-circle start">0</div>
          <div class="progressBar-text">
            <!-- <p class="progressBar-text-label">Dato inicial</p>
            <p class="progressBar-text-message">0$</p> -->
          </div>
        </div>
        <div :style="`width: ${goalData.progress}%`" class="progressBar-bar positive"></div>
        <div :style="`left: ${goalData.progress}%`" class="progressBar-point-elapsed">
          <div class="progressBar-circle proccess">
            {{ goalData.progress }}%
            <span></span>
          </div>
          <div class="progressBar-text">
            <p class="progressBar-text-label">Historias creadas</p>
            <p class="progressBar-text-message">{{ historiesMaked }}</p>
          </div>
        </div>
        <div class="progressBar-point-end">
          <div class="progressBar-circle end">
            100
            <span>%</span>
          </div>
          <div class="progressBar-text">
            <p class="progressBar-text-label">Meta</p>
            <p class="progressBar-text-message">{{ goalData.goalData.goal }} Historias</p>
          </div>
        </div>
      </div>
      <div class="progressBar-legend">
        <div class="progressBar-text">
          <div class="progressBar-text-legend">
            <div class="progressBar-legend-box"></div>
            <p class="progressBar-text-label">
              <strong>{{ leftHistories }}</strong>
              Historias restantes
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="progressBar-awards" :key="awardsKey" v-if="$store.getters['user/getRole'] !== 'monitor'">
      <Award v-for="award in goalData.awards" :key="award._id" :award="award" :modelId="modelInfo._id" :isBar="true" />
    </div>
  </div>
</template>
<script>
import Award from "../awards/Award.vue";

export default {
  components: { Award },

  props: ["modelInfo", "goalData", "isGoalEdit", "awardsKey"],
  data() {
    return {
      leftHistories: 0,
      historiesMaked: 0,
    };
  },
  methods: {
    calcProgress() {
      this.goalData.progress = 0;
      const historiesModified = this.goalData.increase - this.goalData.decrease;
      const totalHistories = historiesModified + this.goalData.progress;
      this.historiesMaked = totalHistories;
      this.leftHistories = this.goalData.goalData.goal - totalHistories;
      this.leftHistories = this.leftHistories >= 0 ? this.leftHistories : 0;

      let progress = Math.floor((this.historiesMaked * 100) / this.goalData.goalData.goal);
      progress = progress < 0 ? 0 : progress;
      this.goalData.progress = progress > 100 ? 100 : progress;
      this.goalData.goalData.finished = this.goalData.progress >= 100 ? true : false;
      this.$emit("updateAwards", this.goalData.goalData);
    },
    calcSubGoals() {
      this.goalData.goalData.subGoals.forEach((subGoal) => {
        const subGoalProgress = (subGoal.value * 100) / this.goalData.goalData.goal;
        subGoal.progress = Math.floor(subGoalProgress);
        subGoal.finished = this.historiesMaked >= subGoal.value ? true : false;
        this.$emit("updateAwards", subGoal);
      });
    },
    calcData() {
      this.calcProgress();
      this.calcSubGoals();
    },
  },
  watch: {
    "goalData.increase": {
      deep: true,
      async handler() {
        this.calcData();
      },
    },
    "goalData.decrease": {
      deep: true,
      async handler() {
        this.calcData();
      },
    },
  },
  async beforeMount() {
    this.calcData();
  },
};
</script>
<style lang="scss"></style>
