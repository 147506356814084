<template>
  <div>
    <div class="barGoal" v-if="goalData && goalData.enable && goalData.type !== 'topmodel'">
      <DateBar v-if="goalData.type === 'days'" :progress="goal.progress" :modelData="modelData" :awards="goalAwards" :goal="goalData" />
      <HoursBar v-if="goalData.type === 'hours'" :progress="goal.progress" :modelData="modelData" :awards="goalAwards" :goal="goalData" />
      <DollarsBar v-if="goalData.type === 'dollars'" :progress="goal.progress" :modelData="modelData" :awards="goalAwards" :goal="goalData" />
      <div class="progressBar-buttonContainer" v-if="!isModel">
        <div class="progressBar-buttonsContainer">
          <button class="progressBar-button generalBtn" @click="isEdit = !isEdit">Editar</button>
        </div>
        <InputEdit
          v-show="isEdit"
          :goal="goalData"
          :modelData="modelData"
          :progress="goal.progress"
          :awards="goalAwards"
          :isGoalEdit="false"
          @closeEditTime="closeEditGoal"
        />
      </div>
    </div>
    <div class="progressBar-logs" v-if="$userData.role !== 'model'">
      <div class="progressBar-log " v-for="(log, idx) in logs" :key="idx" v-html="transalteLog(log)"></div>
      <a class="progressBar-more" v-if="logs.length < total" @click="getMoreLogs">Ver más</a>
    </div>
  </div>
</template>
<script>
import DateBar from "./barTypes/DateBar.vue";
import DollarsBar from "./barTypes/DollarsBar.vue";
import HoursBar from "./barTypes/HoursBar.vue";
import QuantityBar from "./barTypes/QuantityBar.vue";
import TopModelBar from "./barTypes/TopModelBar.vue";
import InputEdit from "./InputEdit.vue";

export default {
  components: { DateBar, DollarsBar, HoursBar, QuantityBar, InputEdit, TopModelBar },
  props: ["modelData", "goal", "isModel", "modelGoals"],
  data() {
    return {
      isEdit: false,
      logs: [],
      page: 1,
      limit: 5,
      total: 0,
    };
  },
  computed: {
    goalData() {
      return this.$store.state.goals.goals.find((goal) => goal._id === this.goal.goalId);
    },
    goalAwards() {
      const awardsNames = [];
      this.goalData?.subGoals.forEach((subGoal) => awardsNames.push(subGoal.award.name));
      awardsNames.push(this.goalData.award.name);
      return awardsNames.map((award) => this.modelGoals.awards.find((modelAward) => modelAward.name === award));
    },
  },
  methods: {
    async closeEditGoal() {
      this.isEdit = false;
      this.page = 1;
      this.total = 0;
      this.logs = await this.getLogs();
    },
    transalteLog(log) {
      let message;
      const { updateAction } = log;
      if (updateAction?.isFromApi) {
        message = `
        <div class="goalCardLog goalCardLog--date">Fecha: <strong>${log.date.substring(0, 10)}</strong>.</div>
        <div class="goalCardLog">
        El sistema
        aumentó <strong>${
          updateAction.goal.type === "hours" ? this.$global.parseMinutesToTime(updateAction.value) : this.$global.formatPrice(updateAction.value)
        }</strong> el progreso de la modelo 
        <strong>${log.modelArtisticName}</strong> en la meta <strong>${updateAction.goal.name}</strong>.
          </div>
        `;
      } else {
        if (log.role === "monitor") {
          message = `
          <div class="goalCardLog goalCardLog--date">Fecha: <strong>${log.declaredDate.substring(0, 10)}</strong>.</div>
          <div class="goalCardLog">
          El monitor 
          <strong>${log.userName}</strong> 
          aumentó el progreso de la modelo 
          <strong>${log.modelArtisticName}</strong> a 
          <strong>${log.valueUpdated}</strong> en la meta <strong>${log.goalUpdated}</strong>.
          </div>
          `;
        }
        if (log.role === "superadmin" || log.role === "coordinator") {
          message = ` 
          <div class="goalCardLog goalCardLog--date"><strong>Fecha:</strong> ${log?.date?.substring(0, 10) || ""}.</div>
          <div class="goalCardLog goalCardLog--info">
            <strong>${log.userName}</strong> 
            ${updateAction?.action === "increase" ? "aumentó" : "disminuyó"}
            <strong>${this.$global.parseDaysToDate(updateAction?.value)}</strong>
            de <strong>${updateAction?.goal?.name || ""}</strong> a
            <strong>${log.modelArtisticName}.</strong>
          </div>
          <div class="goalCardLog goalCardLog--reason">
            <strong>Descripción: </strong>${updateAction?.reason || ""}
          </div>
          `;
        }
      }
      return message;
    },
    async getLogs() {
      const {
        data: { allNotify, total, error },
      } = await this.$api.get(
        `/notifications?type=Goals&page=${this.page}&limit=${this.limit}&goalId=${this.goal.goalId}&artisticName=${this.modelData?.artisticName}&log=true`
      );
      if (error) {
        this.total = 0;
        console.log(`Error al cargar el log de la modelo ${this.modelData?.artisticName}`);
        return [];
      }
      this.total = total;
      return allNotify || [];
    },
    async getMoreLogs() {
      this.page++;
      const _logs = await this.getLogs();
      this.logs.push(..._logs);
    },
  },
  async beforeMount() {
    this.logs = await this.getLogs();
  },
};
</script>

<style lang="scss">
.goalsBarCard {
  padding-bottom: 20px;
  border-bottom: 1px solid $silver;
}

.goalsBarCard:last-child {
  border-bottom: none;
}

.goalBarContainer {
  margin-top: 20px;
}

.days-goal {
  .progressBar {
    &-container {
      &:nth-child(2) {
        margin-top: 30px;
      }
    }

    &-legend {
      justify-content: space-around;
      margin-top: 70px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-left: 20px;
      .progressBar-text {
        text-align: left;
      }
    }
  }
}

.progressBar {
  width: 100%;
  height: 8px;
  display: flex;
  font-size: 10px;
  position: relative;
  background: $primary_color;

  &-logs {
    width: 90%;
    margin: auto;
  }
  &-log {
    @include Column(flex-start);
    width: 100%;
    margin: $mpadding/3 0;
    padding: $mpadding/3;
    font-size: 0.7em;
    background-color: $polar;
    box-shadow: $dshadow;
    border-radius: 6px;
    .goalCardLog {
      width: 100%;
      margin: 0 $mpadding/3;
      padding: $mpadding/5;
      align-self: flex-start;
      flex-grow: 0;
      &--date {
        width: 130px;
        flex-shrink: 0;
      }
      &--reason,
      &--info {
        flex-grow: 1;
      }
    }
  }
  &-more {
    display: block;
    font-size: 0.9em;
    margin: $mpadding auto 0 auto;
    text-align: center;
    cursor: pointer;
  }
  &-container {
    width: 100%;
    margin-top: 85px;
    padding: 0px 50px;
  }

  &-awards {
    display: flex;
    margin-left: 10px;
    margin-bottom: 10px;
    margin-top: 15px;
    svg {
      height: 60px;
      width: 60px;
      text {
        font-family: $sec_font;
      }
    }
  }

  &-circle {
    @include Row;
    color: white;
    font-weight: bold;
    width: 24px;
    height: 24px;
    z-index: 1;
    border-radius: 50%;
    background: $japanese_laurel;
    span {
      display: none;
    }
  }
  .end {
    background: $primary_color;
  }

  &-point {
    &-start {
      @include Column;
      position: absolute;
      left: -40px;
      top: -8px;
    }
    &-end {
      @include Column;
      position: absolute;
      right: -40px;
      top: -8px;
    }
    &-elapsed {
      @include Column;
      flex-direction: column-reverse;
      align-self: flex-end;
      position: absolute;
      z-index: 2;
      bottom: -8px;
      left: 0;
      margin-left: -20px;
    }
    &-subgoal {
      @include Column;
      align-self: flex-end;
      position: absolute;
      z-index: 2;
      bottom: -22px;
      left: 0;
      margin-left: -20px;
      .progressBar-circle {
        width: 18px;
        height: 18px;
        background: $primary_color;
      }
      p {
        font-size: 8px;
        margin: 0;
      }
    }
  }

  &-bar {
    position: absolute;
  }

  .positive {
    height: 8px;
    background: $japanese_laurel;
    width: 0;
  }

  .proccess {
    margin-top: 5px;
  }

  .finished {
    background: $japanese_laurel;
  }

  &-text {
    margin-top: 5px;
    width: 60px;
    text-align: center;
    line-height: 12px;
    &-label {
      color: rgba(0, 0, 0, 0.832);
    }

    &-message {
      margin-top: 4px;
      font-weight: bold;
    }

    &-legend {
      display: flex;
      gap: 5px;
      align-items: center;
    }

    &-trophy {
      width: 30px;
    }
  }

  &-legend {
    font-size: 10px;
    display: flex;
    margin-top: 65px;
    justify-content: flex-end;

    &-box {
      height: 5px;
      width: 20px;
      background: $primary_color;
      &.withUs {
        background: $japanese_laurel;
      }
    }

    .progressBar-text {
      margin-top: 10px;
      margin-right: -32px;
      width: auto;
    }
  }

  &-buttonContainer {
    margin-top: 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &-button {
    background-color: $gray-emperor;
    display: block;
    margin: 0 2px;
  }
}

.goalFinished {
  color: $primary_color;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}

@media (min-width: $tablet_width) {
  .progressBar {
    height: 12px;
    font-size: 13px;

    &-logs {
      width: 98%;
    }
    &-log {
      margin: $mpadding/3 auto;
      padding: initial;
      flex-direction: row;
      font-size: 0.8em;
      background-color: initial;
      box-shadow: unset;
      border-radius: unset;
      .goalCardLog {
        padding: $mpadding/3;
        background-color: $polar;
        box-shadow: $dshadow;
        border-radius: 6px;
      }
    }
    &-text {
      line-height: 15px;
    }

    &-legend {
      font-size: 13px;

      .progressBar-text {
        margin-right: -50px;
      }
    }

    &-awards {
      margin-left: 20px;
      img {
        margin-right: 5px;
      }
    }

    &-bar {
      height: 12px;
    }

    &-circle {
      width: 32px;
      height: 32px;
      font-size: 10px;

      span {
        display: block;
      }
    }

    &-container {
      padding: 0 80px;
      margin-top: 95px;
    }

    &-point {
      &-elapsed {
        bottom: -10px;
        margin-left: -52px;
      }
      &-start {
        top: -10px;
        left: -52px;
      }
      &-end {
        top: -10px;
        right: -52px;
      }
      &-subgoal {
        bottom: -25px;
        margin-left: -52px;
        .progressBar-circle {
          width: 22px;
          height: 22px;
        }
        p {
          font-size: 10px;
        }
      }
    }

    &-text {
      width: 104px;
    }
    .positive {
      height: 12px;
    }
  }

  .days-goal {
    .progressBar {
      &-container {
        margin-top: 115px;
        &:nth-child(2) {
          margin-top: 40px;
        }
      }

      &-legend {
        justify-content: space-around;
        margin-top: 90px;
        margin-bottom: 35px;
        flex-direction: row;
        gap: 0px;
        margin-left: 0px;
        .progressBar-text {
          text-align: center;
        }
      }
    }
  }
}

@media (min-width: $tv_width) {
  .goalBarContainer {
    flex-direction: column;
  }

  .progressBar {
    &-buttonContainer {
      margin-top: 0px;
      margin-left: 20px;
      width: 153px;
    }

    &-container {
      width: 70%;
    }
  }
}

.barGoal {
  @include tvWidth() {
    display: flex;
    .goalBarContainer {
      flex-wrap: wrap;
      width: 100%;
      padding-bottom: 30px;
    }

    .progressBar-container {
      width: 100%;
    }
  }
}
</style>
