<template>
  <div class="goalBarContainer" v-if="false">
    <strong class="modelsGoalsCard-name">{{ goal.goalName }}</strong>
    <div class="progressBar-container">
      <div class="progressBar">
        <div class="progressBar-point-start ">
          <div class="progressBar-circle start">0</div>
          <div class="progressBar-text"></div>
        </div>
        <div :style="`width: ${percent}%`" class="progressBar-bar positive"></div>
        <div :style="`left: ${percent}%`" class="progressBar-point-elapsed">
          <div class="progressBar-circle proccess">
            {{ percent }}
            <span>%</span>
          </div>
          <div class="progressBar-text" v-if="goal.subType === 'hours'">
            <p class="progressBar-text-label">Tiempo trabajado</p>
            <p class="progressBar-text-message">{{ $global.parseMinutesToTime(data.accumulatedTime) }}</p>
          </div>
          <div class="progressBar-text" v-if="goal.subType === 'dollars'">
            <p class="progressBar-text-label">Dolares facturados</p>
            <p class="progressBar-text-message">{{ $global.formatPrice(data.accumulatedDollars) }}</p>
          </div>
        </div>
        <div class="progressBar-point-end">
          <div class="progressBar-circle end">
            100
            <span>%</span>
          </div>
          <div class="progressBar-text" v-if="goal.subType === 'hours'">
            <p class="progressBar-text-label">Meta</p>
            <p class="progressBar-text-message">{{ $global.parseMinutesToTime(goal.goal) }}</p>
          </div>
          <div class="progressBar-text" v-if="goal.subType === 'dollars'">
            <p class="progressBar-text-label">Meta</p>
            <p class="progressBar-text-message">{{ $global.formatPrice(goal.goal) }}</p>
          </div>
        </div>
      </div>
      <div class="progressBar-legend">
        <div class="progressBar-text" v-if="goal.subType === 'hours'">
          <div class="progressBar-text-legend">
            <div class="progressBar-legend-box"></div>
            <p class="progressBar-text-label">
              Tiempo restante
            </p>
          </div>
          <p class="progressBar-text-message">{{ $global.parseMinutesToTime(this.timeLeft) || "0 horas" }}</p>
        </div>
        <div class="progressBar-text" v-if="goal.subType === 'dollars'">
          <div class="progressBar-text-legend">
            <div class="progressBar-legend-box"></div>
            <p class="progressBar-text-label">
              <strong>{{ $global.formatPrice(leftDollars) }}</strong>
              restantes
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="progressBar-awards">
      <Award v-for="award in awards" :key="award._id" :award="award" :modelId="modelInfo._id" :isBar="true" />
    </div>
  </div>
</template>
<script>
import Award from "../awards/Award.vue";

export default {
  components: { Award },
  props: ["modelData", "modelGoals", "goal"],
};
</script>
