<template>
  <div :class="['inputEdit__container', isGoalEdit ? `c${goal._id} isEdit` : '']">
    <strong class="inputEdit__title">Editar Meta</strong>
    <div class="inputEdit__field">
      <p class="inputEdit__label">Nombre de la meta</p>
      <input class="inputEdit__input" type="text" placeholder="Escribe un nombre" v-model="goalName" />
    </div>
    <div class="inputEdit__field">
      <p class="inputEdit__label">Tipo de meta</p>
      <select v-model="goalSubType" class="inputEdit__input">
        <option value="hours">Horas</option>
        <option value="dollars">Dolares</option>
      </select>
    </div>
    <div class="inputEdit__timeInput" v-if="goalSubType === 'hours'">
      <p class="inputEdit__label">Horas</p>
      <div>
        <input class="inputEdit__input" type="text" v-model="input" placeholder="1h 30min" />
        <div class="inputEdit__infoBanner">
          <p>Escriba de esta forma: 1h 30min</p>
          <p>h = Horas</p>
          <p>min = Minutos</p>
        </div>
      </div>
      <div v-if="message" class="inputEdit__timeToAdd">
        <div class="inputEdit__timeToAddBar"></div>
        <p>{{ message }}</p>
      </div>
    </div>

    <div class="inputEdit__timeInput" v-if="goalSubType === 'dollars'">
      <p class="inputEdit__label">Dolares</p>
      <div>
        <input class="inputEdit__input" type="number" v-model.number="input" placeholder="Escribe la cantidad" />
      </div>
      <div v-if="message" class="inputEdit__timeToAdd">
        <div class="inputEdit__timeToAddBar"></div>
        <p>{{ message }}</p>
      </div>
    </div>
    <ToggleInput v-if="isGoalEdit" :label="check ? 'Ocultar meta' : 'Activar meta'" :checked="check" @isChecked="handleCheck" />
    <div class="inputEdit__buttons">
      <button class="inputEdit__acceptBtn generalBtn" @click="sendUpdate" :disabled="buttonDisabled">
        Aceptar
      </button>
      <button class="inputEdit__cancelBtn generalBtn" @click="$emit('closeEditTime'), resetValues()">Cancelar</button>
    </div>
  </div>
</template>
<script>
import SubGoal from "./SubGoal.vue";
import ToggleInput from "../../input/ToggleInput.vue";

export default {
  components: { SubGoal, ToggleInput },
  props: ["goal", "isGoalEdit"],
  data() {
    return {
      goalSubType: "",
      goalName: "",
      input: "",
      message: "",
      value: "",
      check: null,
    };
  },
  methods: {
    async sendUpdate() {
      const { enable, subType, name, goal } = this.goal;
      const goalData = {};
      let value = +this.value;
      if (enable !== this.check) goalData.enable = this.check;
      if (goal !== value) goalData.goal = value;
      if (name !== this.goalName) goalData.name = this.goalName;
      if (subType !== this.goalSubType) goalData.subType = this.goalSubType;

      if (!Object.keys(goalData).length) return this.$emit("closeEditTime");

      goalData.id = this.goal._id;
      goalData.ref = "topmodel";
      this.$emit("updateGoal", goalData);
    },
    resetValues() {
      this.goalSubType = this.goal.subType;
      this.goalName = this.goal.name;
      this.check = this.goal.enable;
    },
    handleCheck(val) {
      this.check = val;
    },
  },
  computed: {
    buttonDisabled() {
      if (this.goalSubType !== this.goal.subType && !this.value) {
        return true;
      }
      if (this.value || this.goalName) {
        return false;
      }
      return true;
    },
  },
  watch: {
    input() {
      if (this.goalSubType === "hours") {
        const { message, mins } = this.$global.parseInputToTime(this.input.toLowerCase(), "hours");
        this.message = message;
        this.value = mins;
      }
      if (this.goalSubType === "dollars") {
        this.message = this.$global.formatPrice(this.input);
        this.value = this.input;
      }
    },
    goalSubType() {
      if (this.goalSubType === "hours") {
        if (this.goal.subType === "hours") {
          this.input = this.$global.getInputValue(this.goal.goal, "hours");
          return;
        }
        this.input = "";
        this.message = "";
        this.value = "";
        return;
      }
      if (this.goalSubType === "dollars") {
        if (this.goal.subType === "dollars") {
          this.input = this.goal.goal;
          return;
        }
        this.input = 0;
        this.message = "";
        this.value = "";
        return;
      }
    },
  },
  mounted() {
    this.resetValues();
  },
};
</script>
<style lang="scss">
.inputEdit__field {
  .inputEdit__input {
    &:read-only {
      border: 1px solid $silver;
    }
  }
}
</style>
