<template>
  <div :class="['inputEdit__container', isGoalEdit ? `c${goalData.goalData._id} isEdit` : '']">
    <strong v-if="isGoalEdit" class="inputEdit__title">Editar Meta</strong>
    <strong v-else class="inputEdit__title">Editar Progreso de Meta</strong>
    <div class="inputEdit__select" v-if="!isGoalEdit">
      <p>Selecciona una opción</p>
      <b-form-radio-group v-model="selected" :options="options" value-field="item" text-field="name"></b-form-radio-group>
    </div>
    <div class="inputEdit__field" v-if="isGoalEdit">
      <p class="inputEdit__label">Nombre de la meta</p>
      <input class="inputEdit__input" type="text" placeholder="Escribe un nombre" v-model="goalName" />
    </div>
    <div class="inputEdit__timeInput">
      <p class="inputEdit__label">Historias</p>
      <div>
        <input class="inputEdit__input" type="number" placeholder="Escribe la cantidad" v-model="value" />
      </div>
      <div v-if="message" class="inputEdit__timeToAdd">
        <div class="inputEdit__timeToAddBar"></div>
        <p>{{ message }}</p>
      </div>
    </div>
    <ToggleInput v-if="isGoalEdit" :label="check ? 'Ocultar meta' : 'Activar meta'" ref="test" :checked="check" @isChecked="updateGoalState" />
    <div class="inputEdit__buttons">
      <button class="inputEdit__acceptBtn generalBtn" @click="sendUpdate" :disabled="buttonDisabled">
        Aceptar
      </button>
      <button class="inputEdit__cancelBtn generalBtn" @click="$emit('closeEditTime')">Cancelar</button>
    </div>
  </div>
</template>
<script>
import ToggleInput from "../../input/ToggleInput.vue";

export default {
  components: { ToggleInput },
  props: ["modelInfo", "goalData", "isGoalEdit"],
  data() {
    return {
      goalName: "",
      value: "",
      selected: "decrease",
      options: [
        { item: "decrease", name: "Disminuir" },
        { item: "increase", name: "Aumentar" },
      ],
      check: true,
    };
  },
  methods: {
    async updateGoalState() {
      this.check = !this.check;
      await this.$store.dispatch("editGoal", { newState: true, enable: this.check, goalId: this.goalData.goalData._id });
    },
    async sendUpdate() {
      let valueToSend = this.value;
      if (this.selected === "decrease") {
        const difference = this.goalData.increase - this.goalData.decrease;
        valueToSend = +this.value > difference ? difference : this.value;
      }
      this.$emit("sendUpdate", { value: +valueToSend, name: this.goalName, selected: this.selected, ref: "quantity", subGoalsToEdit: [] });
    },
  },
  computed: {
    buttonDisabled() {
      if ((this.isGoalEdit && this.value) || (this.isGoalEdit && this.goalName)) {
        return false;
      }
      if (!this.isGoalEdit && this.value) {
        return false;
      }
      return true;
    },
    message() {
      if (!this.value) {
        return null;
      }

      return `${this.value} historias`;
    },
  },
  beforeMount() {
    this.check = this.goalData.goalData.enable;
    this.goalName = this.goalData.goalData.goalName;
    this.value = this.goalData.goalData.goal;
  },
};
</script>
<style lang="scss"></style>
