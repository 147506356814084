<template>
  <div class="awardContainer">
    <iconic :name="award.name" :class="[`c${award._id}${modelId}`, isBlocked ? 'isBlocked' : '']" />
  </div>
</template>
<script>
export default {
  props: ["modelId", "award", "isBar", "isModelProfile"],
  data() {
    return {
      isBlocked: false,
    };
  },
  methods: {
    setAwards() {
      this.isBlocked = this.award.value <= 0;
      if (!["topmodel_liston", "loyalty_fee"].includes(this.award.name)) {
        const trophyRef = document.querySelector(`.c${this.award._id}${this.modelId} text`);
        trophyRef.textContent = this.isBar || this.isBlocked ? "" : this.award.value;
      }

      if (this.isBar && !this.award.finished) {
        const award = document.querySelector(`.c${this.award._id}${this.modelId}`);
        award.style.display = "none";
      }
      if (this.isModelProfile && this.award.value <= 0) {
        const award = document.querySelector(`.c${this.award._id}${this.modelId}`);
        award.style.display = "none";
      }
    },
  },
  mounted() {
    this.setAwards();
  },
};
</script>
<style lang="scss">
.awardContainer {
  height: 100%;
}

.icon {
  svg {
    width: 100px;
    height: 100px;
  }
}

.isBlocked {
  filter: grayscale(1);
}
</style>
